.panel {
  background: rgb(42, 53, 113);
  background: -moz-linear-gradient(180deg, rgba(42, 53, 113, 1) 0%, rgba(20, 32, 74, 1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(42, 53, 113, 1) 0%, rgba(20, 32, 74, 1) 100%);
  background: linear-gradient(180deg, rgba(42, 53, 113, 1) 0%, rgba(20, 32, 74, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2a3571", endColorstr="#14204a", GradientType=1);

  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-top: 10px;
  min-height: 800px;
  width: 250px;
}

.logo {
  display: block;
  margin: 40px auto 140px;
}

.menu {
  font: {
    size: 18px;
  };

  li {
    cursor: pointer;
    height: 40px;
    line-height: 40px;
    margin-bottom: 10px;
    padding-left: 58px;
    position: relative;

    svg {
      fill: #ffffff;
      margin-right: 20px;
    }

    &.active,
    &:hover {
      color: #FFE900;

      svg {
        fill: #FFE900;
      }

      &::before {
        border: 7px solid #FFE900;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        content: "";
        width: 0;
        background: #FFE900;
        height: 100%;
        position: absolute;
        left: -7px;
      }
    }
  }

  .disable {
    cursor: auto;

    &:hover {
      color: #FFFFFF;

      svg {
        fill: #FFFFFF;
      }

      &::before {
        left: -10000px;
      }
    }
  }
}

.menu_admin {
  font: {
    size: 16px;
  };

  li {
    height: 30px;
    line-height: 30px;
    margin-bottom: 0;
  }
}

.menu_other {
  margin-top: 200px;
}

.social_menu {
  margin-top: 70px ;
  padding-left: 58px;

  li {
    float: left;
    margin-right: 12px;
    padding-bottom: 50px;
  }
}

.disable {
  opacity: .21;
}

.divider {
  margin: 20px 60px;
  width: auto !important;
}

.warning {
  color: red!important;
}

.checkCircle {
  color: green!important;
}