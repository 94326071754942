.panel {
  align-items: center;
  display: flex;
  justify-content: right;
  margin: 60px 0 48.5px;

  h2 {
    font: {
      family: 'Courier New', monospace;
      size: 12px;
      weight: bold;
    };
    text-align: left;
    text-transform: uppercase;
  }

  p {
    color: #FFE900;
    font: {
      size: 16px;
    };
  }

  svg {
    margin: 0 40px 0 30px;
  }
}