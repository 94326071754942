.panel {
  background-color: #0F0F1C;
  border-radius: 10px;
  margin: 0 12px;
  padding: 15px;
  width: 50%;
}

.dashboard {
  h2 {
    font: {
      size: 25px;
      weight: normal;
    };
    margin-bottom: 20px;
    margin-left: 30px;
    text-align: left;
    width: 100%;
  }
}

.chartContainer {
  color: #000000;
}