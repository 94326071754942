.page {
  margin-inline: 0!important;
  min-height: 100vh;
  width: calc(100% - 250px);
}

.panel {
  align-items: flex-start!important;
  width: 100%;
}

.board {
  display: flex;
  margin: 0 12px 24px;
}