.depositPanel {
  flex-direction: column;
  position: relative;
  width: 100%;
}

.arrow {
  margin: 0 !important;
  order: 2;
  position: absolute;
  top: 86px;
}

.first {
  margin: 4px 0 !important;
  order: 1;
  width: 100%;
}

.last {
  margin: 4px 0 !important;
  order: 3;
  width: 100%;
}

.masterPanel {
  display: flex;
  justify-content: center;
  width: 100%;
}

.innerPanel {
  background-color: #0f0f1c;
  border-radius: 10px;
  display: flex;
  margin: 0 12px;
  padding: 15px;
  width: 100%;

  h2 {
    font: {
      size: 25px;
      weight: normal;
    };
    margin-top: 20px;
    margin-left: 30px;
    text-align: left;
    width: 100%;
  }

  .btn {
    background-color: #FFE900;
    border-radius: 5px;
    color: #192C65;
    font: {
      size: 16px;
      weight: bold;
    };
    height: 50px;
    width: 220px;

    &:disabled {
      cursor: default;
      opacity: .5;
    }
  }
}

.row {
  background-color: rgba(#1B3171, .86);
  display: flex;
  height: 110px;

  .box {
    flex: 1;
    margin: 0 0 0 34px;

    &:last-of-type {
      margin-right: 40px;

      .small {
        text-align: right;
      }
    }

    .small {
      font-size: 14px;
      line-height: 18px;
      margin: 8px 0;
    }

    .token {
      background-color: #0f0f1c;
      border-radius: 25px;
      color: #ffffff;
      display: flex;
      font: {
        size: 16px;
        weight: bold;
      }
      line-height: 42px;
      height: 42px;
      text-transform: uppercase;
      width: 200px;

      img {
        margin: 8px;
      }
    }

    .max {
      background-color: #2446FF;
      border: 2px solid #70C8EF;
      border-radius: 25px;
      color: #70C8EF;
      cursor: pointer;
      font: {
        size: 16px;
        weight: bold;
      }
      line-height: 36px;
      height: 40px;
      margin-right: 20px;
      text-align: center;
      text-transform: uppercase;
      opacity: .56;
      width: 60px;
    }

    input {
      border: none;
      font-size: 28px;
      padding-left: 0;

      &:focus,
      &:focus-within,
      &:focus-visible {
        border: none;
        box-shadow: none;
        outline: none;
      }
    }

    .tokenBox {
      display: flex;
      height: 40px;
      justify-content: flex-end;
    }
  }
}