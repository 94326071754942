.panel {
  background-color: #0F0F1C;
  border-radius: 10px;
  margin: 0 12px;
  padding: 15px;
  width: 50%;
}

.dashboard {
  h2 {
    font: {
      size: 25px;
      weight: normal;
    };
    margin-bottom: 20px;
    margin-left: 30px;
    text-align: left;
    width: 100%;
  }
}

.card {
  background-color: #FFE900;
  border-radius: 15px;
  color: #000000;
  justify-content: space-between;
  min-height: 90px;
  padding: 0 20px;
  width: 100%;

  .label {
    font: {
      size: 18px;
    };
  }

  .data {
    font: {
      size: 25px;
    };
    text-align: right;

    small {
      font: {
        size: 12px;
      };
      display: block;
    }
  }


}